import React, {Component} from "react";
import Layout from "../components/layout";
import {graphql} from "gatsby";
import {buildBlocks} from "../services/builder";
import {CoreFields} from "./fragment";  // eslint-disable-line
import {CoreBlockFragment} from "../components/blocks/CoreBlock";  // eslint-disable-line

class Post extends Component {

    render() {
      this.props.pageContext.pageTitle = this.props.data.wpBranch.Meta.title ? this.props.data.wpBranch.Meta.title : this.props.data.wpBranch.title;
      this.props.pageContext.acfFields = this.props.data.wpBranch.BranchInformation;
      const blocks = buildBlocks(
        this.props.data.wpBranch.blocks,
        {
          branchInformation: this.props.data.wpBranch.BranchInformation,
          openingHours: this.props.data.wpBranch.OpeningHours
        },
        this.props.pageContext);

      return (
      <Layout
        meta={this.props.data.wpBranch.Meta}
        path={this.props.pageContext.pagePath}
        title={this.props.data.wpBranch.title}
        themeOptions={this.props.data.wp.themeOptions}
      >
          {blocks}
      </Layout>
      )
    }
}

export const query = graphql`
  query ($id: String) {
    wpBranch(id: {eq: $id}) {
      id
      slug
      title
      Meta {
        fieldGroupName
        metaDescription
        metaTitle
        ogDescription
        ogTitle
        twitterCardType
        twitterDescription
        twitterTitle
        twitterImage {
          uri
        }
        ogImage{
          uri
        }        
      }    
      blocks {
        ...CoreBlock
        ...CoreFields
        innerBlocks {
          ...CoreBlock
          ...CoreFields
          innerBlocks {
            ...CoreBlock
            ...CoreFields
            innerBlocks {
              ...CoreBlock
              ...CoreFields
              innerBlocks {
                ...CoreBlock
                ...CoreFields
              }
            }
          }
        }
      }
      BranchInformation {
        address1
        address2
        displayLocation
        email
        latitude
        longitude
        managerForename
        managerSurname
        name
        postcode
        region
        telephone
        town
        what3words
        managerPhoto {
          sourceUrl
          localFile {
            publicURL
            childImageSharp {
                gatsbyImageData(
                  width: 960,
                  aspectRatio: 1,
                  placeholder: BLURRED,
                  formats: [AUTO, WEBP, AVIF],
                  transformOptions: {fit: COVER, cropFocus: CENTER},
                  quality: 90
                )
            }
          }
        }
      }
      OpeningHours {
        fieldGroupName
        monday {
          close
          open
        }
        tuesday {
          close
          open
        }
        wednesday {
          close
          open
        }
        thursday {
          close
          open
        }
        friday {
          close
          open
        }
        saturday {
          close
          open
        }
        sunday {
          close
          open
        }
      }
    },
    wp {
      themeOptions {
        ThemeOptions {
          siteLogo {
            mediaItemUrl
          }
        }
      }
    }
}
`;

export default Post
